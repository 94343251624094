<template>
  <div id="app">
    <div class="header">
      <img
        width="185"
        height="35"
        src="./assets/header/logo.png"
        class="logo"
      />
      <div class="nav">
        <router-link to="/">首页</router-link>
        <router-link to="/join">如何加入BmileX生态建设</router-link>
        <router-link to="/whitePaper">白皮书</router-link>
        <router-link to="/zhaop">招募合作</router-link>
        <router-link to="/about">关于我们</router-link>
        <!-- <router-link to="/download">App下载</router-link> -->
      </div>
    </div>
    <div class="mobil_header">
      <img
        width="185"
        height="35"
        src="./assets/header/logo.png"
        class="logo"
      />

      <a-popover v-model="visible" placement="bottomRight" id="navs">
        <template slot="content">
          <div class="navmain">
            <router-link to="/" @click.native="tohide">首页</router-link>
            <router-link to="/join" @click.native="tohide"
              >如何加入</router-link
            >
            <router-link to="/whitePaper" @click.native="tohide"
              >白皮书</router-link
            >
            <router-link to="/zhaop" @click.native="tohide"
              >招募合作</router-link
            >
            <router-link to="/about" @click.native="tohide"
              >关于我们</router-link
            >
            <!-- <router-link to="/download">App下载</router-link> -->
          </div>
        </template>
        <div class="iconWrap">
          <img
            width="35"
            height="25"
            src="./assets/mobile/openIcon.png"
            class="openIcon"
          />
        </div>
      </a-popover>

      <!-- <div class="iconWrap">
        <img
          width="35"
          height="25"
          src="./assets/mobile/openIcon.png"
          class="openIcon"
        />
      </div>
      <div class="navmain">
        <router-link to="/">首页</router-link>
        <router-link to="/join">如何加入</router-link>
        <router-link to="/whitePaper">白皮书</router-link>
        <router-link to="/zhaop">招募合作</router-link>
        <router-link to="/about">关于我们</router-link>
        <router-link to="/download">App下载</router-link>
      </div>  -->
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    tohide() {
      this.visible = false;
    },
  },
};
</script>
<style>
*,
html,
body,
#app {
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100vh;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 0;
}

.header {
  width: 100%;
  height: 100px;
  background: #1b2029;
  opacity: 0.8;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
}

.header .logo {
  width: 185px;
  height: 35px;
  display: inline-block;
  margin-left: 137px;
  margin-top: 32px;
}

.header .nav {
  margin-left: 272px;
  margin-top: 42px;
}
.header .nav a {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;

  color: #ffffff;
  margin-right: 30px;
  text-decoration: none;
}

.nav a.router-link-exact-active {
  color: #46ffff;
}
.nav a:hover {
  color: #46ffff;
}

.mobil_header {
  width: 750px;
  height: 100px;
  background: #1b2029;
  opacity: 0.8;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
}

.mobil_header .logo {
  width: 185px;
  height: 35px;
  display: inline-block;
  margin-left: 33px;
  margin-top: 34px;
}

.mobil_header {
  display: none;
}
@media (max-width: 959px) {
  .header {
    display: none;
  }
  .mobil_header {
    display: block;
  }
}
@media (min-width: 960px) {
  .header {
    display: block;
  }
  .header img,
  .header .nav {
    float: left;
  }
  .mobil_header {
    display: none;
  }
}

.mobil_header .iconWrap {
  float: right;
  width: 100px;
  height: 100px;
}
.mobil_header img.openIcon {
  margin-top: 40px;
  display: block;
  width: 35px;
  height: 25px;
  /* margin-right: 31px; */
}
</style>
<style>
.ant-popover-arrow {
  display: none !important;
}
.ant-popover {
  top: 100px !important;
  right: 0 !important;
  padding-top: 0 !important;
}
.ant-popover-content {
  width: 200px;
  background: #1b2029 !important;
  opacity: 0.8 !important;
  position: absolute;
  right: 0;
  top: 100%;
}
.ant-popover-inner {
  background: none !important;
}
.ant-popover-inner-content .navmain a {
  display: block !important;
  font-size: 18px !important;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 75px;
  width: 100% !important;
  text-align: center !important;

  color: #ffffff !important;
  text-decoration: none !important;
}

.ant-popover-inner-content .navmain a.router-link-exact-active {
  color: #46ffff !important;
}
.ant-popover-inner-content .navmain a:hover {
  color: #46ffff !important;
}
</style>